import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonUnderline from "../components/Button/ButtonUnderline";
import HeroAlignStart from "../components/Hero/HeroAlignStart";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import Certifications from "../components/Repeating/Certifications";
import CTABackgroundColor from "../components/CTA/CTABackgroundColor";
import InstagramFeed from "../components/Repeating/InstagramFeed";

const Page = ({ data }) => {
   const heroImages = [
      data.heroDesktop.childImageSharp.fixed,
      {
         ...data.heroMobile.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="San Diego Dog Boutique | Grooming & Training | Bella Woof"
            description="Bella Woof is a top San Diego dog boutique offering professional grooming, certified training, & premium pet products. Pamper your pet today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroAlignStart
            backgroundImages={heroImages}
            backgroundSizeDesktop="cover"
            backgroundSizeMobile="cover"
            backgroundPositionDesktop="50% 100%"
            backgroundPositionMobile="50% 100%"
            minHeightDesktop="704px"
            minHeightMobile="640px"
         >
            <div className="text-center">
               <p className="text-mobile7xl md:text-7xl font-heading leading-tight md:leading-tighter text-white font-extrabold tracking-widest uppercase mb-4">
                  Groom.
                  <br className="md:hidden" /> Train.
                  <br className="md:hidden" /> Eat.
                  <br className="md:hidden" /> Wear.
               </p>
               <p className="text-white text-mobile4xl md:text-4xl leading-snug md:font-bold">Because dogs deserve all the good things.</p>
               <ButtonSolid href="tel:+1-619-237-0730" text="(619) 237-0730" className="gtm-phone-number" />
            </div>
         </HeroAlignStart>

         <div className="container">
            <section className="pt-14 md:pt-28 pb-20 md:pb-28">
               <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-16 lg:gap-x-28 items-center">
                  <div>
                     <h1>Your San Diego & La Jolla Dog Boutique for Grooming, Training & Retail</h1>
                     <p>
                        Bella Woof is a woman-owned, full-service professional dog boutique that provides first-class professional grooming services, professionally vetted premium pet products, and top-notch, certified, professional training for your best furry friends. In 2018, our owner April acquired the Grooming, Training & Retail Boutique operating for 3+ years in Little Italy and renamed it Bella Woof. In June 2021, Bella Woof moved out of Little Italy to a larger, new space in La Jolla and continues to offer Grooming, Training and Retail, but with a larger, now dedicated area for private training and classes.
                     </p>
                     <p>
                        April has been serving the animal and pet community in the San Diego area since 1998. She has held significant animal health, wellness & behavior modification roles at San Diego Zoo & Safari Park, San Diego Humane Society, and the University of California Davis for 25 years. 
                     </p>
                     <p>
                        At Bella Woof, we believe that dogs play a very important part in people's lives and we want to help foster that relationship! We are not only experienced, professional groomers and trainers, we are also animal health and wellness advocates and educators who are here to provide the best care and products for your pets. We also carry premium pet products for cats!
                     </p>
                     <ButtonUnderline href="/about/" text="Learn More" />
                  </div>
                  <div>
                     <Img fluid={data.intro.childImageSharp.fluid} alt="Your San Diego Dog Boutique for Grooming, Training & Retail" />
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-28">
               <div className="grid md:grid-cols-12 gap-y-7 items-center ">
                  <div className="md:col-start-1 md:col-span-7">
                     <Img fluid={data.groom.childImageSharp.fluid} alt="Dog Grooming" className="rounded-3xl" />
                  </div>
                  <div className="md:col-end-13 md:col-span-5 md:pl-24">
                     <h2>Dog Grooming in our Boutique</h2>
                     <p>
                        Our professional groomers and bathers have the technical skills, knowledge, and experience to care for your pet in a safe and pleasant environment. Our staff is trained on proper equipment use, canine anatomy, as well as pet safety and handling. Your dogs are in good hands (literally!) from start to finish.
                     </p>
                     <ButtonUnderline href="/professional-dog-grooming/" text="Browse Services" />
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <div className="grid md:grid-cols-12 gap-y-7 items-center">
                  <div className="order-2 md:order-1 md:col-start-1 md:col-span-5 md:pr-24">
                     <h2>Puppy & Dog Training</h2>
                     <p>
                        We follow the highest standards of training for your dogs. Our Chief Training Officer, April Bove-Rothwell, is a licensed training specialist with an extensive background in Animal Biology. Bella Woof offers training for puppies and adult dogs alike in different settings.
                     </p>
                     <ButtonUnderline href="/certified-dog-training/" text="Learn More" />
                  </div>
                  <div className="order-1 md:order-2 md:col-end-13 md:col-span-7">
                     <Img fluid={data.train.childImageSharp.fluid} alt="Puppy & Dog Training" className="rounded-3xl" />
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <div className="grid md:grid-cols-3 gap-y-10 md:gap-x-10">
                  <div className="rounded-3xl overflow-hidden relative shadow-global">
                     <Img fluid={data.customizedTraining.childImageSharp.fluid} alt="Customized Puppy & Dog Training" />
                     <div className="px-6 py-5 md:py-8">
                        <h3 className="text-lg">Customized Puppy & Dog Training</h3>
                        <p className="mb-0">
                           We’ll work with you and your pets in an environment familiar to them—your home! Customized introductory and advanced
                           training packages are available.
                        </p>
                     </div>
                     <AniLink fade to="/at-home-dog-training/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="rounded-3xl overflow-hidden relative shadow-global">
                     <Img fluid={data.puppyTraining.childImageSharp.fluid} alt="Puppy Training Classes" />
                     <div className="px-6 py-5 md:py-8">
                        <h3 className="text-lg">Puppy Training Classes</h3>
                        <p className="mb-0">
                           Teach your pup basic obedience skills, receive instructions on puppy socialization, and solve common puppy problems like
                           mouthing, house-training, and jumping.
                        </p>
                     </div>
                     <AniLink fade to="/san-diego-puppy-training-classes/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="rounded-3xl overflow-hidden relative shadow-global">
                     <Img fluid={data.adultDogTraining.childImageSharp.fluid} alt="Adult Dog Training Classes" />
                     <div className="px-6 py-5 md:py-8">
                        <h3 className="text-lg">Adult Dog Training Classes</h3>
                        <p className="mb-0">
                           Older dogs certainly can learn new tricks! Train areas of immediate concern and get advanced training when they’re ready.
                        </p>
                     </div>
                     <AniLink fade to="/adult-dog-training-classes/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <div className="grid md:grid-cols-12 gap-y-7 items-center">
                  <div className="md:col-start-1 md:col-span-7">
                     <Img fluid={data.retail.childImageSharp.fluid} alt="Premium Pet Products for Dogs & Cats" className="rounded-3xl" />
                  </div>
                  <div className="md:col-end-13 md:col-span-5 md:pl-24">
                     <h2>Premium Pet Products for Dogs & Cats</h2>
                     <p>
                        Our pet products at Bella Woof are carefully researched and curated so your pets are nourished well from the inside out. Choose from our selection of natural, holistic, and high-quality food, bathing and grooming products, and other pet supplies.
                     </p>
                     <ButtonUnderline href="/premium-pet-products/" text="Learn More" />
                  </div>
               </div>
            </section>
         </div>

         <WhyUs className="mb-20 md:mb-32" />

         <About className="mb-20 md:mb-32" />

         <Certifications className="mb-20 md:mb-32" />

         <TestimonialBackgroundImage />

         <CTABackgroundColor>
            <h3 className="font-semibold">Ready to pamper your pet? Get in touch to schedule an appointment, book training, or learn more! </h3>
         </CTABackgroundColor>

         <InstagramFeed />

         <section className="bg-primary py-8 md:py-10 text-center">
            <div className="container">
               <span className="text-white text-opacity-80 md:text-lg font-heading font-semibold">
                  Check out our{" "}
                  <a
                     href="https://www.facebook.com/bellawooflajolla/"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="underline text-white hover:text-white"
                  >
                     Facebook page
                  </a>{" "}
                  for upcoming puppy and dog training classes, Yappy Hour and adoption events!
               </span>
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Homepage_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Homepage_Twitter.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "home/1.0 Homepage_Hero_Desktop.jpg" }) {
         childImageSharp {
            fixed(width: 1440, height: 704, quality: 100) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      heroMobile: file(relativePath: { eq: "home/1.0-Homepage_Hero_Mobile.jpg" }) {
         childImageSharp {
            fixed(width: 750, height: 1280, quality: 100) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      intro: file(relativePath: { eq: "home/2.0-Intro.png" }) {
         childImageSharp {
            fluid(maxWidth: 1256, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      groom: file(relativePath: { eq: "home/3.0 Groom.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1392, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      train: file(relativePath: { eq: "home/4.0 Train.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1392, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      customizedTraining: file(relativePath: { eq: "home/4.1 At-Home Dog Training.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      puppyTraining: file(relativePath: { eq: "home/4.2 Puppy Training Classes.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      adultDogTraining: file(relativePath: { eq: "home/4.3 Adult Dog Classes.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      retail: file(relativePath: { eq: "home/retailv2.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1392, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Page;
