import React from 'react'
import styled from '@emotion/styled';
import tw from 'twin.macro'

const Button = styled.a`
    ${tw`relative inline-block text-gray-900 text-base font-bold pb-1 cursor-pointer focus:outline-none`}
    letter-spacing: 0.97px;
    &:after {
        content: '';
        ${tw`absolute w-full bottom-0 left-0 right-auto bg-primary h-px transition-all duration-500 ease-in-out`}
    }
    &:hover, 
    &:active,
    &:focus {
        &:after {
            ${tw`w-0 right-0 left-auto`}
        }
    }
`

export default props => <Button className={props.className} href={props.href} target={props.target} rel={props.rel} data-modal-open={props.data}>{props.text}</Button>;