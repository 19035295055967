import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Certifications = ({className}) => {

    const data = useStaticQuery(graphql`
        {
			ABMA: file(relativePath: {eq: "repeating/certifications/abma-logo.png"}) {
				childImageSharp {
					fluid(maxWidth: 128, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
            }
			CCPDT: file(relativePath: {eq: "repeating/certifications/ccpdt-logo.png"}) {
				childImageSharp {
					fluid(maxWidth: 162, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
            }
            CGC: file(relativePath: {eq: "repeating/certifications/CGC-logo.png"}) {
				childImageSharp {
					fluid(maxWidth: 88, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
            }
            petFirstAid: file(relativePath: {eq: "repeating/certifications/pet-first-aid.jpg"}) {
				childImageSharp {
					fluid(maxWidth: 88, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
            }
            SATS: file(relativePath: {eq: "repeating/certifications/SATS Certified Animal Trainer (SATS).png"}) {
				childImageSharp {
					fluid(maxWidth: 62, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
			}   
            SDHS: file(relativePath: {eq: "repeating/certifications/san-diego-humane-society.png"}) {
				childImageSharp {
					fluid(maxWidth: 162, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
			}    
            trupanion: file(relativePath: {eq: "repeating/certifications/trupanion.png"}) {
				childImageSharp {
					fluid(maxWidth: 162, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
			}    
            freshPatch: file(relativePath: {eq: "repeating/certifications/fresh-patch.png"}) {
				childImageSharp {
					fluid(maxWidth: 162, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
			}              					
        }
    `)   

    return (
        <section className={`${className}`}>
            <div className="container">
                <div className="grid grid-cols-12 md:grid-cols-5 gap-x-4 md:gap-x-10 gap-y-6 text-center">
                    <div className="col-span-6 md:col-auto order-4 md:order-1">
                        <Img className="mb-3 md:mb-8 mx-auto" fluid={data.CCPDT.childImageSharp.fluid} alt="Certification Council Pro Dog Trainers" />
                        <p className="text-xs text-black font-heading font-semibold mb-0">Certification Council Pro Dog Trainers</p>
                    </div>
                    <div className="col-span-4 md:col-auto order-2 md:order-2">
                        <Img className="mb-3 md:mb-8 mx-auto" fluid={data.CGC.childImageSharp.fluid} alt="American Kennel Club" />
                        <p className="text-xs text-black font-heading font-semibold mb-0">American Kennel Club</p>
                    </div>
                    <div className="col-span-4 md:col-auto order-1 md:order-3">
                        <Img className="mb-3 md:mb-8 mx-auto" fluid={data.SATS.childImageSharp.fluid} alt="Syn Alia Training Systems" />
                        <p className="text-xs text-black font-heading font-semibold mb-0">Syn Alia Training Systems</p>
                    </div>
                    <div className="col-span-4 md:col-auto order-3 md:order-4">
                        <Img className="mb-3 md:mb-8 mx-auto" fluid={data.petFirstAid.childImageSharp.fluid} alt="Pet First Aid & CPR Certified" />
                        <p className="text-xs text-black font-heading font-semibold mb-0">Pet First Aid & CPR Certified</p>
                    </div>
                    <div className="col-span-6 md:col-auto order-5 md:order-5">
                        <Img className="mb-3 md:mb-8 mx-auto" fluid={data.ABMA.childImageSharp.fluid} alt="Animal Behavior Management Alliance" />
                        <p className="text-xs text-black font-heading font-semibold mb-0">Animal Behavior Management Alliance</p>
                    </div>     
                    <div className="col-span-4 md:col-auto order-6 md:order-6 md:col-start-2">
                        <Img className="mt-6 mb-3 md:mb-8 mx-auto" fluid={data.trupanion.childImageSharp.fluid} alt="Trupanion" />
                        <p className="text-xs text-black font-heading font-semibold mb-0 ">Trupanion</p>
                    </div>          
                    <div className="col-span-4 md:col-auto order-7 md:order-7 md:col-start-3">
                        <Img className="mb-3 md:mb-8 mx-auto" fluid={data.SDHS.childImageSharp.fluid} alt="San Diego Humane Society" />
                        <p className="text-xs text-black font-heading font-semibold mb-0">San Diego Humane Society</p>
                    </div>   
                    <div className="col-span-4 md:col-auto order-8 md:order-8 md:col-start-4">
                        <Img className="mb-3 md:mb-8 mx-auto" fluid={data.freshPatch.childImageSharp.fluid} alt="Fresh Patch" />
                        <p className="text-xs text-black font-heading font-semibold mb-0 ">Fresh Patch</p>
                    </div>                                  				
                </div>  
            </div>  
        </section>
    );
}

export default Certifications;