import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import ButtonUnderline from "../Button/ButtonUnderline";

const About = ({ className }) => {
   const data = useStaticQuery(graphql`
      {
         aboutUs: file(relativePath: { eq: "repeating/about-us/April-Robert.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 1096, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
      }
   `);

   return (
      <section className={`${className}`}>
         <div className="container">
            <div className="grid md:grid-cols-2 items-center gap-y-7">
               <div className="order-2 md:order-1 md:pr-24">
                  <h2>Meet April and the Team</h2>
                  <p>
                     Bella Woof is run by wife and husband team, April and Robert, out of the charming Village of La Jolla on Girard Avenue. With April's expertise in animal biology, nutrition and behavior and Robert's business acumen, the result is a unique experience that supports your pet's health and wellness, improving your invaluable bond with your pet.
                  </p>
                  <ButtonUnderline href="/about/" text="Learn More" />
               </div>
               <div className="order-1 md:order-2">
                  <Img className="rounded-3xl md:ml-auto" fluid={data.aboutUs.childImageSharp.fluid} alt="About Us" />
               </div>
            </div>
         </div>
      </section>
   );
};

export default About;
