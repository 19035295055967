import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Services = ({ className, contentAlignment, heading }) => {
   const data = useStaticQuery(graphql`
      {
         specialPets: file(relativePath: { eq: "repeating/why-us/Each Pet is Special.svg" }) {
            publicURL
         }
         holisticApproach: file(relativePath: { eq: "repeating/why-us/Holistic Approach.svg" }) {
            publicURL
         }
         multipleLocations: file(relativePath: { eq: "repeating/why-us/Multiple Locations.svg" }) {
            publicURL
         }
         oneStopShop: file(relativePath: { eq: "repeating/why-us/One-Stop Shop.svg" }) {
            publicURL
         }
         provenExperience: file(relativePath: { eq: "repeating/why-us/Proven Experience.svg" }) {
            publicURL
         }
         topShelfProducts: file(relativePath: { eq: "repeating/why-us/Top Shelf Products.svg" }) {
            publicURL
         }
      }
   `);

   return (
      <section className={`${className}`}>
         <div className="container">
            {/* { heading ?
				<header className="mb-14 md:mb-18 md:text-center">
					<h2>{heading}</h2>
				</header>
			: null 
			} */}
            <header className="mb-14 md:mb-20 text-center">
               <h2>Why Pets Love Us</h2>
            </header>

            <div className={`grid grid-cols-1 md:grid-cols-3 md:gap-x-16 lg:gap-x-32 gap-y-10 md:gap-y-16 text-center mx-auto ${contentAlignment}`}>
               <div>
                  <img className={`mb-4 md:mb-3 text-center mx-auto ${contentAlignment}`} src={data.oneStopShop.publicURL} alt="One-Stop Shop" />
                  <p className="text-lg font-heading text-gray-900 font-bold mb-4">One-Stop Shop</p>
                  <p className="mb-0">We’re a full-service dog boutique for all your grooming, training, and retail needs.</p>
               </div>
               <div>
                  <img
                     className={`mb-4 md:mb-3 text-center mx-auto ${contentAlignment}`}
                     src={data.multipleLocations.publicURL}
                     alt="Multiple Locations"
                  />
                  <p className="text-lg font-heading text-gray-900 font-bold mb-4">Multiple Locations</p>
                  <p className="mb-0">Get onsite training in La Jolla, at the San Diego Humane Society, or at your home!</p>
               </div>
               <div>
                  <img
                     className={`mb-4 md:mb-3 text-center mx-auto ${contentAlignment}`}
                     src={data.provenExperience.publicURL}
                     alt="Proven Experience"
                  />
                  <p className="text-lg font-heading text-gray-900 font-bold mb-4">Proven Experience</p>
                  <p className="mb-0">April is a certified professional dog trainer with 20 years of experience.</p>
               </div>
               <div>
                  <img
                     className={`mb-4 md:mb-3 text-center mx-auto ${contentAlignment}`}
                     src={data.topShelfProducts.publicURL}
                     alt="Top-Shelf Products"
                  />
                  <p className="text-lg font-heading text-gray-900 font-bold mb-4">Top-Shelf Products</p>
                  <p className="mb-0">We offer natural, holistic, and high-quality pet products that are vetted first.</p>
               </div>
               <div>
                  <img
                     className={`mb-4 md:mb-3 text-center mx-auto ${contentAlignment}`}
                     src={data.holisticApproach.publicURL}
                     alt="Holistic Approach"
                  />
                  <p className="text-lg font-heading text-gray-900 font-bold mb-4">Holistic Approach</p>
                  <p className="mb-0">We consider behavior and nutrition in a holistic approach to pets’ well-being.</p>
               </div>
               <div>
                  <img className={`mb-4 md:mb-3 text-center mx-auto ${contentAlignment}`} src={data.specialPets.publicURL} alt="Each Pet Special" />
                  <p className="text-lg font-heading text-gray-900 font-bold mb-4">Each Pet Special</p>
                  <p className="mb-0">Customized training and grooming considers each pet’s unique needs.</p>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Services;
